<template>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
      <van-field
        v-model="order.refundReason"
        name="refundReason"
        label="退款原因"
        placeholder=""
        disabled
      />
      <van-field
        v-model="order.refundAmount"
        name="refundAmount"
        label="退款金额"
        placeholder=""
        disabled
      />
      <van-field
        v-model="order.payAmount"
        name="payAmount"
        label="实付金额"
        placeholder=""
        disabled
      />
      <van-field name="result" label="审核">
        <template #input>
          <van-radio-group v-model="result" direction="horizontal">
            <van-radio name="true">同意</van-radio>
            <van-radio name="false">拒绝</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
  <!-- <div>
    <div style="text-align:center;background:#fff;padding:15px;">
      <h5>退款前，请买房与卖方多沟通协商</h5>
      <h5 style="margin:7px 0 0 0;">尽可能避免线上交易纠纷</h5>
    </div>
    <div style="text-align:center;background:#fff;padding:30px 0;margin:15px 0 0 0;">
      <van-icon name="after-sale" size="5rem" />
      <h5 style="color:#f00;">退款金额 &yen; {{ order.refundAmount }}</h5>
    </div>
    <van-cell-group title="退款信息">
      <van-cell title="退款原因" :value="order.refundReason" />
      <van-cell title="申请时间" :value="order.refundCreateTime" />
    </van-cell-group>
    <van-action-bar>
      <van-action-bar-button type="danger" text="同意退款" @click="onAccept" />
      <van-action-bar-button type="warning" text="拒绝" @click="onRefuse" />
    </van-action-bar>
  </div> -->
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      orderId: useRoute.query.orderId,
      order: {},
      result: 'false'
    })
    const onSubmit = (values) => {
      post('/order.verifyRefund', {
        orderId: state.orderId,
        result: state.result,
        reason: ''
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
          useRouter.go(-1)
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      post('/order.get', {
        orderId: state.orderId
      }).then(res => {
        state.order = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
